@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css');
html,
body {
	background-color: #fff;
	overflow-x: hidden !important;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	height: 100%;
	margin: 0;
	font-family: Circular !important;
	color: #000;
	scroll-behavior: smooth;
}

@font-face {
	font-family: Circular;
	src: url('../circle.ttf');
}

@media only screen and (max-width: 768px) {
	html,
	body {
		overflow-x: hidden !important;
	}
}

a {
	text-decoration: none !important;
}

.nav-item a {
	color: #000;
	font-family: Circular;
}

.nav-item a:hover {
	color: #186d4c;
}

.bg-sign {
	background-color: #186d4c !important;
	border-color: #186d4c !important;
	border-radius: 8px !important;
}

.logo {
	width: 280px;
}

@media (max-width: 767.98px) {
	.desktop {
		display: none !important;
	}
}

@media (min-width: 992px) {
	.mobile {
		display: none !important;
	}
}

@media (min-width: 992px) {
	li.nav-item {
		padding-left: 27px;
	}
}

.banner {
	background-image: url('./img/hero.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 250px 20px;
}

.home-banner {
	background-image: url('./img/header.png') !important;
}

.banner h1 {
	font-size: 56px;
	font-weight: 500;
	line-height: 70px;
}

.banner h6 {
	font-size: 20px;
	font-weight: 400;
	line-height: 25px;
}

.banner h3 {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
}

.bg-search {
	margin-top: -120px;
	border-radius: 8px;
}

.search-btn {
	width: 16rem;
	margin-top: -8px;
}

.search-control {
	padding: 1.37rem 3.5rem !important;
}

.search-btn {
	width: 16rem;
	margin-top: -15px;
	padding: 1.34rem !important;
}

.bg-counter {
	background-color: #ebfff7;
}

.bg-counter h2 {
	font-size: 30px;
	font-weight: 500;
	line-height: 37px;
	color: #1e1e1e;
}

.bg-counter h3 {
	font-size: 44px;
	font-weight: 500;
	line-height: 55px;
	color: #186d4c;
}

.bg-counter h6 {
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
	color: #000000;
}

.topping {
	margin-top: 70px;
}

.heading h6 {
	color: #5f5f5f;
	font-size: 20px;
	font-weight: 450;
	line-height: 25px;
}

.heading h2 {
	color: #1e1e1e;
	font-size: 50px;
	font-weight: 500;
	line-height: 64px;
}

.availablelisting {
	padding: 100px 10px;
}

.star-rate {
	margin-top: -40px;
	position: relative;
	margin-right: 20px;
}

.availablelisting p {
	color: #797979;
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.author h6 {
	color: #000;
	font-size: 16px;
	line-height: 20px;
	font-weight: 500;
	margin-bottom: 0;
}

.author p {
	color: #797979;
	font-size: 12px;
	line-height: 15px;
	font-weight: 400;
	margin-bottom: 0;
}

.card-list {
	box-shadow: 0px 9px 18px rgba(0, 0, 0, 0.12);
	border-radius: 10px;
}

.green {
	color: #186d4c;
}

.how-work {
	padding: 100px 10px;
	background-color: #f5fefb;
}

.card-works {
	text-align: center;
	background: #ffffff;
	border: 1px solid #cdcdcd;
	border-radius: 10px;
	padding: 60px 30px;
	min-height: 330px;
}

.card-works h2 {
	color: #1e1e1e;
	font-size: 24px;
	line-height: 30px;
	font-weight: 500;
}

.left-login {
	background-image: url('./img/left.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	color: #fff;
}

.right-login {
	background-image: url('./img/right.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	color: #fff;
}

.loginoptions {
	height: 100%;
}

@media (max-width: 1200px) {
	.left {
		height: 50%;
	}
	.right {
		height: 50%;
	}
}

.loginoptions h4 {
	color: #195840;
	line-height: 20px;
	font-size: 32px;
	font-weight: 500;
}

.loginoptions p {
	color: #70707b;
	line-height: 20px;
	font-size: 20px;
	font-weight: 450;
}

.box {
	box-shadow: 0px 4px 12px rgba(51, 51, 51, 0.05);
	border-radius: 4px;
}

.register-section {
	padding: 90px 20px;
}

.register-section h2 {
	color: #18181b;
	font-size: 36px;
	line-height: 44px;
	font-weight: 600;
}

.register-section h6 {
	color: #70707b;
	font-size: 18px;
	line-height: 28px;
	font-weight: 400;
}

.application label {
	font-weight: 600;
	color: #3f3f46;
	font-size: 14px;
	line-height: 20px;
}

.register-section label {
	font-weight: 500;
	color: #3f3f46;
	font-size: 14px;
	line-height: 20px;
}

.form-control {
	padding: 13px !important;
}

label {
	font-weight: 600;
	color: #3f3f46;
	font-size: 14px;
	line-height: 20px;
}

.register-section .form-control {
	padding: 13px;
}

.input-group-text {
	background: #f5fefb !important;
	font-size: 15px !important;
	font-weight: 600 !important;
}

.instru {
	color: #70707b;
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
}

.agree {
	color: #70707b !important;
	font-size: 14px !important;
	line-height: 20px !important;
	font-weight: 600 !important;
}
.agree a {
	color: #2ea275;
}

.separator {
	align-items: center;
	display: flex;
}

.separator .line {
	background-color: #e6e6e6;
	flex: 1 1;
	height: 1px;
}

.separator h6 {
	margin-bottom: 0;
	padding: 0 10px;
}

.google-sign {
	border-radius: 0px 4px 4px 0px !important;
}

.register-btn {
	padding: 12px !important;
}

.fp {
	margin-top: -36px;
	color: #2ea275;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
}

.cancel-btn {
	border-color: #d1d1d6 !important;
	color: #18181b !important;
	font-weight: 500 !important;
	background-color: #fff !important;
	padding: 12px !important;
}

.login-btn {
	background-color: #37c28d !important;
	padding: 12px !important;
	border-color: #37c28d !important;
	color: #18181b !important;
	font-weight: 500 !important;
}

.del-btn {
	background-color: #d92c20 !important;
	padding: 12px !important;
	border-color: #d92c20 !important;
	color: #fff !important;
	font-weight: 500 !important;
}

@media (min-width: 992px) {
	.otp-form {
		width: 65px !important;
		height: 63px;
		border: 1px solid #2ea275 !important;
		text-align: center;
		font-size: 24px !important;
		font-weight: bold !important;
		color: #2ea275 !important;
		margin: 0 10px;
	}
}

@media (max-width: 767.98px) {
	.otp-form {
		width: 45px !important;
		height: 40px;
		border: 1px solid #2ea275 !important;
		text-align: center;
		font-size: 24px !important;
		font-weight: bold !important;
		color: #2ea275 !important;
	}
}

.application {
	padding: 70px 10px;
}

.application h2 {
	color: #18181b;
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
}

.application h6 {
	color: #70707b;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #ddf5ec !important;
	color: #18181b !important;
}

.subset .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #fff!important;
	color: #6941C6!important;
}

.subset .nav-link{
	padding: 9px 34px 9px 0;
}

.subset .nav-item {
	padding-left: 0px;
}

.application .form-control {
	padding: 13px;
}

.application .form-select {
	padding: 13px;
}

.sc-jSUZER.SKBRq,
.jBlnID {
	max-width: 1148px !important;
	height: 48px;
	border: dashed 1px #d1d1d6 !important;
	padding: 70px 23px 70px 23px !important;
	border-radius: 5px !important;
	cursor: pointer;
	-webkit-box-flex: 0;
	-webkit-flex-grow: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
}

.custom-class {
	table-layout: fixed;
}

.separator h3 {
	margin-bottom: 0;
	padding: 0 14px;
	color: #3f3f46;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.nav-pills .nav-link {
	color: #51525c !important;
	font-size: 14px;
	line-height: 45px;
	font-weight: 500;
}

@media (min-width: 992px) {
	.form-width {
		padding: 0px 200px 0px 100px;
	}
}

.bg-tips {
	background-color: #25815e;
	border-radius: 8px;
}

.bg-tips h4 {
	font-size: 14px;
	line-height: 20px;
	font-size: 700;
}

.bg-tips p {
	font-size: 13px;
	line-height: 18px;
	font-weight: 600;
	color: #fff;
	margin-top: 12px;
}

.listed h6 {
	color: #1c6146;
	line-height: 20px;
	font-weight: 600;
	font-size: 14px;
	margin-bottom: 0px;
}

.listed p {
	color: #2ea275;
	line-height: 20px;
	font-weight: 400;
	font-size: 14px;
}

.bg-list {
	border: 1px solid #e4e4e7;
	padding: 15px;
	border-radius: 8px;
	margin-bottom: 20px;
}

.dropdown-menu {
	box-shadow: 0px 20px 25px -5px rgba(46, 162, 117, 0.1),
		0px 10px 10px -5px rgba(46, 162, 117, 0.04) !important;
}

.dropdown-item:hover {
	background-color: transparent !important;
	color: #000!important;
}

@media (max-width: 767.98px) {
	.sidebar {
		margin: 0;
		padding: 0;
		width: 300px;
		background-color: #12412f;
		position: fixed;
		height: 100%;
		overflow: auto;
		background-image: url('./img/pattern.svg');
	}
}

@media (min-width: 992px) {
	.sidebar {
		margin: 0;
		padding: 0;
		width: 300px;
		background-color: #12412f;
		position: fixed;
		height: 100%;
		overflow: auto;
		background-image: url('./img/pattern.svg');
		display: flex;
	}
}

@media (min-width: 992px) {
	.sidebar a {
		display: block;
		color: #fff;
		padding: 16px;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 8px;
		line-height: 24px;
		text-decoration: none;
	}
}

@media (max-width: 767.98px) {
	.sidebar a {
		display: block;
		color: #fff;
		padding: 16px;
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 8px;
		line-height: 24px;
		text-decoration: none;
		text-align: left !important;
	}
}


.active-link {
	background-color: #fafafa;
	color: #12412f!important;
	border-radius: 4px;
}

.sidebar a:hover:not(.active) {
	background-color: #fafafa;
	color: #12412f;
	border-radius: 4px;
}

@media (min-width: 992px) {
	div.content {
		margin-left: 290px;
		padding: 30px 60px;
		min-height: auto;
	}
}

@media (max-width: 767.98px) {
	div.content {
		margin-left: 269px;
		padding: 30px 10px;
		min-height: 1600px;
	}
}

@media screen and (max-width: 700px) {
	.sidebar {
		width: 100%;
		height: auto;
		position: relative;
	}
	.sidebar a {
		float: left;
	}
	div.content {
		margin-left: 0;
	}
}

@media screen and (max-width: 400px) {
	.sidebar a {
		text-align: center;
		float: none;
	}
}

.nots {
	color: #fff !important;
	background-color: #a1c349 !important;
}

@media (min-width: 992px) {
	.nav-bottom {
		margin-top: 110px;
	}
}

@media (min-width: 992px) {
	.nav-bottom-cso {
		margin-top: 200px;
	}
}

.nav-bottom h5 {
	margin-bottom: 0px;
}

.overview h2 {
	font-size: 30px;
	color: #18181b;
	font-weight: 600;
	line-height: 38px;
}

/* .has-search .form-control {
  padding-left: 2.375rem;
} */

.has-search .form-control-feedback {
	position: absolute;
	z-index: 2;
	display: none;
	width: 2.375rem;
	height: 2.375rem;
	line-height: 2.375rem;
	text-align: center;
	pointer-events: none;
	color: #aaa;
}

.gray {
	background-color: #fafafa;
}

.group-cards {
	border: 1px solid #e4e4e7;
	border-radius: 8px;
}

.group-cards h6 {
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	color: #70707b;
}

.group-cards h5 {
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;
	color: #26272b;
}

.underline {
	color: #fff;
	opacity: 1;
}

.recent-act h4 {
	color: #3f3f46;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	margin-bottom: 0px;
}

.time {
	color: #3f3f46;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

@media (min-width: 992px) {
	.left-act {
		padding-left: 50px !important;
	}
}

.btn-claer {
	border: 1px solid #e4e4e7 !important;
	margin-top: 34px;
}

.btn-msg {
	border: 1px solid #e4e4e7 !important;
	margin-top: 20px;
}

.bg-listed {
	border: 0.5px solid #e4e4e7;
	box-shadow: 0px 2px 4px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
	background-color: #fdf2f2;
	padding: 25px 20px;
}

.bg-listed h6 {
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
	color: #A0A0AB;
}

.bg-listed h5 {
	font-size: 24px;
	line-height: 30px;
	font-weight: 600;
	color: #26272b;
}

.group-cards h1 {
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	color: #26272b;
}

.tab-lists {
	background: #ddf5ec;
	font-size: 12px;
	padding: 6px 16px;
	border-radius: 30px;
	color: #1c6146;
	font-weight: 600;
}

.table > :not(caption) > * > * {
	padding: 23px !important;
}

.table > thead {
	vertical-align: bottom;
	border-top: 1px solid #e4e4e7 !important;
}

.table .t {
	border-top: 0px !important;
}

.approved {
	background: #ddf5ec;
	font-size: 12px;
	padding: 6px 16px;
	border-radius: 30px;
	font-weight: 600;
	color: #1c6146;
	width: 40%;
}

.reject {
	background: #fee4e2;
	font-size: 10px;
	padding: 6px 16px;
	border-radius: 30px;
	color: #b42318;
	font-weight: 600;
	width: 40%;
}

.pending {
	background: #f4f4f5;
	font-size: 10px;
	padding: 6px 16px;
	border-radius: 30px;
	color: #3f3f46;
	font-weight: 600;
	width: 40%;
}

.approved-listing {
	font-size: 15px;
	border-radius: 30px;
	font-weight: 600;
	color: #1c6146;
}

.reject-listing {
	font-size: 15px;
	border-radius: 30px;
	color: #b42318;
	font-weight: 600;
}

.pending-listing {
	font-size: 15px;
	border-radius: 30px;
	color: #3f3f46;
	font-weight: 600;
}

td {
	color: #18181b !important;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

th {
	color: #51525c !important;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.listing-drop {
	border: 1px solid #f4f4f5 !important;
	box-shadow: 0px 25px 50px -12px rgba(46, 162, 117, 0.25) !important;
	border-radius: 6px !important;
}

.listing-item {
	padding: 15px !important;
	font-weight: 400 !important;
}

.modal-header {
	border-bottom: 0px !important;
}

.modal {
	--bs-modal-width: 600px !important;
}

.listing-modal h4 {
	color: #18181b;
	font-weight: 700;
	font-size: 18px;
	line-height: 28px;
}

.listing-modal p {
	color: #51525c;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.actions {
	cursor: pointer !important;
}

.back a {
	color: #70707b;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
}

.statuses h3 {
	color: #18181b;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
}

.statuses h6 {
	color: #3f3f46;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.statuses a {
	color: #25815e;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.statuses p {
	color: #70707b;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.nav-tabs .nav-item {
	padding-left: 0px;
}

.tog {
	background-color: #fafafa !important;
	border: 1px solid #e4e4e7;
	border-radius: 8px;
}

.nav-tabs .nav-link {
	margin: 1px !important;
	border-radius: 10px !important;
	color: #a0a0ab !important;
	border: 0px !important;
}

.nav-tabs .nav-link.active {
	color: #26272b !important;
	background-color: #fff !important;
	border: 1px solid #d1d1d6 !important;
	box-shadow: 0px 1px 3px rgba(46, 162, 117, 0.1),
		0px 1px 2px rgba(46, 162, 117, 0.06) !important;
	border-radius: 8px !important;
}

.listings-tog .bi {
	font-size: 10px;
}

.cso-o h6 {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.cso-o p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	color: #3f3f46;
	margin-bottom: 0px;
}

.msg p {
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	color: #3f3f46;
	margin-bottom: 0px;
}

.co {
	padding: 20px;
}

hr {
	color: #e4e4e7 !important;
	opacity: 0.5 !important;
}

.feeds {
	background: #fafafa;
	margin: 20px 62px 10px 25px;
	padding: 10px;
	border-radius: 8px;
}

.feeds span {
	color: #70707b;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
}

.news-feed {
	height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
}

@media (min-width: 992px) {
	.userdrop {
		width: 400px;
		background: #ffffff;
		border: 1px solid #f4f4f5 !important;
		box-shadow: 0px 20px 25px -5px rgba(46, 162, 117, 0.1),
			0px 10px 10px -5px rgba(46, 162, 117, 0.04) !important;
		border-radius: 8px !important;
		padding: 30px 46px !important;
	}
}

@media (max-width: 767.98px) {
	.userdrop {
		/* width: 400px; */
		background: #ffffff;
		border: 1px solid #f4f4f5 !important;
		box-shadow: 0px 20px 25px -5px rgba(46, 162, 117, 0.1),
			0px 10px 10px -5px rgba(46, 162, 117, 0.04) !important;
		border-radius: 8px !important;
	}
}

.userdrop h5 {
	color: #26272b;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.userdrop h6 {
	color: #26272b;
	font-size: 16px;
	font-weight: 500;
	line-height: 20px;
}

.userdrop p {
	color: #70707b;
	font-size: 12px;
	font-weight: 500;
	line-height: 18px;
}

.dropdown-toggle {
	white-space: nowrap;
	color: #26272b;
}

.act-pro {
	border: 1px solid #e4e4e7;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
}

.act-pro a {
	color: #18181b;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
}

.act-pro h3 {
	color: #18181b;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
}

.act-pro p {
	color: #51525c;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

.act-pro h6 {
	color: #70707b;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
}

.empty {
	padding-top: 100px;
}

.lin {
	border: 1px solid #d1d1d6 !important;
}

.project-view h6 {
	color: #51525c;
	font-size: 18px;
	line-height: 28px;
	font-weight: 400;
}

.project-view h4 {
	color: #26272b;
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
}

.p-d h3 {
	color: #26272b;
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	font-weight: 600;
}

.p-d h2 {
	color: #3f3f46;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	margin-bottom: 21px;
}

.sm {
	color: #3f3f46;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	margin-bottom: 21px;
}

.flex {
	display: flex !important;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.items-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.nav-item .dropdown-menu[data-bs-popper] {
	top: 40px !important;
	right: 0px !important;
	left: unset !important;
}

.user-name-badge {
	width: 40px;
	height: 40px;
	right: 140px;
	background: #f5fefb;
	border-radius: 120px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
}

.user-name-badge-text {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #2ea275;
	margin: 0;
}

.auth-select {
	height: 52px !important;
}

.view-donations p {
	color: #51525c;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.view-donations h6 {
	color: #18181b;
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
}

.donation-desc p {
	color: #70707b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
}

.donation-desc h6 {
	color: #3f3f46;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.bg-don {
	background: #f4f4f5;
	border-radius: 4px;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
	padding: 8px 10px;
}

.separator {
	align-items: center;
	display: flex;
}

.separator .lines {
	background-color: #e6e6e6;
	flex: 1 1;
	height: 1px;
}

.separator h1 {
	margin-bottom: 0;
	padding: 0 14px;
	color: #3f3f46;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.details-bank h5 {
	color: #a0a0ab;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
}

.details-bank h6 {
	color: #344054;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}
.uploader-loading-block {
	display: flex;
	align-items: center;
	/* justify-content: center; */
}

.css-13cymwt-control,
.select__control--is-focused {
	height: 54px;
}

.css-13cymwt-control {
	min-height: 100px!important;
}

.acc-pref h5 {
	color: #26272b;
	font-size: 20px;
	font-weight: 600;
	line-height: 30px;
}

.acc-pref p {
	color: #70707b;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.gray-btn {
	background-color: #fafafa !important;
	padding: 12px !important;
	border-color: #d1d1d6 !important;
	color: #18181b !important;
	font-weight: 500 !important;
}

.bg-lt {
	color: #1c6146;
	background: #ddf5ec;
	/* line-height: 20px; */
	padding: 4px 17px;
	border-radius: 16px;
}

.bg-lt p {
	margin: 0;
}

.n-feeds a {
	color: #18181b;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
}

.n-feeds h6 {
	color: #51525c;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.n-feeds p {
	color: #70707b;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.n-feeds h4 {
	color: #18181b;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.n-feeds h5 {
	color: #18181b;
	font-size: 25px;
	font-weight: 500;
	line-height: 30px;
}

.n-feeds {
	border: 1px solid #e4e4e7;
	padding-bottom: 20px;
	border-radius: 8px;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
}

.op-bg {
	border: 1px solid #d1d1d6;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
}

.op-bg h3 {
	color: #18181b;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
}

.op-bg h4 {
	color: #3f3f46;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.bm {
	padding: 9px 17px 4px 17px;
	border: 1px solid #d1d1d6;
	text-align: center;
	border-radius: 4px;
	color: #51525c;
}

.loading-container {
	margin: 100px 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hloGwT:last-child div:first-child {
	overflow: visible !important;
}

.news-f a {
	color: #26272b;
	font-size: 30px;
	font-weight: 500;
	line-height: 38px;
}

.news-f h2 {
	color: #26272b;
	font-size: 30px;
	font-weight: 500;
	line-height: 38px;
}

.news-f h6 {
	color: #51525c;
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
}

.news-f p {
	color: #70707b;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.other-post h4 {
	color: #26272b;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
}

.other-post h3 {
	color: #51525c;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.other-post h6 {
	color: #70707b;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.bg-resources {
	border: 1px solid #e4e4e7;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
}

.bg-resources h4 {
	color: #18181b;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.bg-resources h5 {
	color: #51525c;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.bg-resources h6 {
	color: #70707b;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.ebook {
	background: #e4e4e7;
	padding: 0px 17px;
	border-radius: 16px;
}

.download-btn {
	margin-top: -38px;
	color: #2ea275;
}

.vid {
	border: 1px solid rgba(209, 209, 209, 0.5);
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 4px;
}

.bg-download {
	border: 1px solid #e4e4e7;
	box-shadow: 0px 1px 2px rgba(46, 162, 117, 0.1);
	border-radius: 8px 8px 0px 0px;
	padding: 40px;
}

.bg-dwd {
	background: #ffffff;
	border: 1px solid #e4e4e7;
	border-radius: 0px 0px 8px 8px;
}

.bg-dwd h4 {
	color: #26272b;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
}

.bg-dwd h5 {
	color: #26272b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.bg-dwd h5 {
	color: #3f3f46;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
}

.dload {
	margin-top: -39px;
	color: #2ea275 !important;
}

.switch-mis {
	background-color: #1c6146;
	border-radius: 40px;
	border: 1px solid #2ea275;
}

.switch-mis:hover {
	border-radius: 40px !important;
}

.password-input {
	padding-right: 50px !important;
}

.table-dropdown {
	overflow: visible !important;
}

.fKzJbZ div {
	overflow: initial !important;
	white-space: normal !important;
}

.msg-b h3,
.msg-b a {
	color: #26272b;
	font-size: 18px;
	line-height: 28px;
	font-weight: 600;
	margin: 0;
}

.msg-b p {
	color: #26272b;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	margin: 0;
}

.contact-section {
	padding: 100px 20px;
	background-color: #fafafa;
}

.contact-section h2 {
	color: #18181b;
	font-size: 60px;
	line-height: 72px;
	font-weight: 600;
}

.contact-section h6 {
	color: #3f3f46;
	font-size: 18px;
	line-height: 28px;
	font-weight: 400;
}

.contact-section p {
	color: #26272b;
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	margin: 0;
}

.contact-section .form-check-input:checked {
	background-color: #37c28d;
	border-color: #37c28d;
}
.onboarding-social-container {
	display: flex;
	justify-content: flex-end;
	margin-top: 5px;
}
.onboarding-social-link {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #2ea275;
	text-align: right;
	cursor: pointer;
}

.pro-img {
	object-fit: cover;
	height: 300px !important;
	object-position: top;
}

.resources-img {
	object-fit: cover !important;
	object-position: top !important;
	height: 300px !important;
}

.news-form {
	padding: 6px 13px !important;
}

.no-head .table > thead {
	vertical-align: bottom;
	border-top: 0px !important;
}

.table {
	margin-bottom: 0rem !important;
}

.add-user .form-control,
.add-user .form-select {
	padding: 8px !important;
}

.sidebar .navbar-toggler {
	margin-left: 293px;
	margin-top: -22px;
}

.up-items h5 {
	color: #18181b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.up-items p {
	color: #3f3f46;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

.up-items {
	border: 1px solid #37c28d;
	padding: 7px;
	border-radius: 8px;
}

.removd {
	color: #3f3f46;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
}

.search-message {
	position: relative;
}

.search-message .bi-search {
	position: absolute;
	top: 7px;
	left: 20px;
	color: #9ca3af;
}

.search-message .form-input {
	height: 38px;
	text-indent: 34px;
	border-radius: 10px;
}

.privacy-section {
	padding: 100px 20px;
	background-color: #fff;
}

.privacy-section h2 {
	color: #26272b;
	font-size: 48px;
	font-weight: 500;
	line-height: 60px;
	letter-spacing: -2%;
}

.privacy-section h4 {
	color: #26272b;
	font-size: 24px;
	font-weight: 500;
	line-height: 32px;
}

.privacy-section p,
.list-privacy li {
	color: #3f3f46;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}

.bg-message {
	background-image: url('./img/messages.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #1d9165;
}

@media (min-width: 992px) {
	div.content-msg {
		padding: 30px 0px 0px 60px !important;
	}
}


.content-msg hr {
	margin: 0;
}

.content-msg a {
	color: #70707b;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.content-msg h4 {
	color: #26272b;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
}

.bg-time {
	background: #fafafa;
	border-radius: 16px;
	padding: 10px 26px;
	font-size: 14px;
	font-weight: 600;
}

.msg-bar {
	box-shadow: 0px 1px 3px rgba(24, 24, 27, 0.1),
		0px 1px 2px rgba(24, 24, 27, 0.06);
	border-radius: 0px 8px 8px 8px;
	color: #26272b;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.org-msg span {
	color: #3f3f46;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
}

.you-msg {
	background-color: #56cfa1;
	box-shadow: 0px 1px 3px rgba(24, 24, 27, 0.1),
		0px 1px 2px rgba(24, 24, 27, 0.06);
	border-radius: 8px 0px 8px 8px;
	padding: 20px 20px 50px 20px;
}

.type-msg .form-control {
	border: 0px;
}

.hr-divider {
	margin-left: -62px;
	margin-right: 0px;
}

.md-btn {
	background-color: #ddf5ec !important;
	padding: 12px !important;
	border-color: #ddf5ec !important;
	color: #25815e !important;
	font-weight: 500 !important;
}

.sbm-btn {
	background-color: #fff !important;
	padding: 12px !important;
	border-color: #d1d1d6 !important;
	color: #3f3f46 !important;
	font-weight: 500 !important;
}

.sbm a {
	color: #70707b;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.sendbulk {
	border: 1px solid #d1d1d6;
	box-shadow: 0px 1px 3px rgba(46, 162, 117, 0.1),
		0px 1px 2px rgba(46, 162, 117, 0.06);
	border-radius: 8px;
}

.paginate {
	justify-content: center;
	margin-top: 50px;
	display: flex;
}

.paginate ul {
	display: flex;
	align-items: center;
	list-style: none;
}

.paginate li {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	background-color: transparent;
	outline: 0;
	cursor: pointer;
	font-size: 12px;
	font-weight: 600;
}

.paginate a {
	display: block;
	padding: 0 6px;
	color: #373737;
}

.paginate a:focus {
	outline: none !important;
}

.paginate a:hover {
	color: #3f3f46;
}

.paginate .active {
	border-color: red;
	outline: none !important;
}

.paginate .active a {
	border-radius: 4px;
	background-color: #37c28d;
}

.paginate .previous {
	border: 1px solid #d1d1d6;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	margin: 0 10px 0 0;
	color: #3f3f46;
	min-width: 60px;
	/* margin-right: 100px; */
}
.paginate .next {
	border: 1px solid #d1d1d6;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	margin: 0 0 0 10px;
	color: #3f3f46;
	min-width: 60px;
	/* margin-left: 100px; */
}

.bg-rankings {
	background-color: #ddf5ec;
	border-radius: 8px;
	padding: 11px 22px;
}

.bg-rankings h3 {
	color: #a0a0ab;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.bg-rankings h2 {
	color: #ca9102;
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
}

.styles-module_dark__xNqje {
	background: #fff !important;
	color: #70707b !important;
	border: 0.5px solid #f4f4f5 !important;
	border-radius: 8px !important;
	width: 19% !important;
	font-size: 12px !important;
	line-height: 18px !important;
	left: 1110.641px !important;
	top: 143.195px !important;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

b {
	font-weight: bolder;
	color: #000;
}

.viewover h4 {
	color: #3f3f46;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
}

.nro {
	padding: 30px 20px;
	border: 1px solid #e4e4e7;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
	cursor: pointer;
}

.nro h6 {
	color: #667085;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.nro h5 {
	color: #101828;
	font-size: 36px;
	font-weight: 600;
	line-height: 44px;
}

.rank-level {
	width: 100px;
}

.rankcard {
	background-color: #fff;
	border: 1px solid #e4e4e7;
	padding: 20px;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
	cursor: pointer;
}

.rankcard:hover {
	background-color: #fff;
	border: 1px solid #12b76a;
	padding: 20px;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
}

.rankcard h6 {
	color: #3f3f46;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
}

.rankie p {
	color: #3f3f46;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.rankie h2 {
	color: #3f3f46;
	font-size: 30px;
	font-weight: 600;
	line-height: 38px;
}

.text-resou {
	border: 1px solid #e4e4e7;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 20px;
}

.text-resou h5 {
	color: #18181b;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.text-resou h6 {
	color: #51525c;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	margin: 0;
}

.text-resou p {
	color: #70707b;
	font-size: 12px;
	font-weight: 400;
	margin: 0;
	line-height: 18px;
}

.btn-view {
	background: #ddf5ec !important;
	color: #25815e !important;
	margin-top: -53px;
}

.btn-views {
	background: #ddf5ec !important;
	color: #25815e !important;
}

.r-text {
	background: #e4e4e7;
	border-radius: 26px;
	padding: 13px 14px;
	line-height: 0px;
	height: 20px;
	font-size: 12px;
}

.r-app {
	background: #ddf5ec;
	border-radius: 26px;
	padding: 13px 14px;
	line-height: 0px;
	height: 20px;
	font-size: 12px;
	color: #1c6146;
}

.single-tex {
	border: 1px solid #e4e4e7;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 8px;
	background-color: #fff;
	padding: 60px 30px;
}

.single-tex h2 {
	color: #18181b;
	font-size: 24px;
	font-weight: 600;
	line-height: 32px;
}

.single-tex h6 {
	color: #51525c;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	margin: 0;
}

.single-tex h3 {
	color: #70707b;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.single-tex p {
	color: #18181b;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.badgers {
	background: #ddf5ec;
	padding: 8px 15px;
	color: #1c6146;
	font-size: 12px;
	line-height: 12px;
	border-radius: 20px;
	font-weight: 500;
}

.pendg {
	background: #f4f4f5;
	padding: 8px 15px;
	color: #3f3f46;
	font-size: 12px;
	line-height: 12px;
	border-radius: 20px;
	font-weight: 500;
}

.rejectedbg {
	background: #ffcccb;
	padding: 8px 15px;
	color: #3f3f46;
	font-size: 12px;
	line-height: 12px;
	border-radius: 20px;
	font-weight: 500;
}

.e-com p {
	color: #70707b;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.creat-comp .form-select {
	padding: 13px !important;
}

.no-head h3 {
	color: #101828;
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
}

.v-c h6 {
	color: #51525c;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.v-c a {
	color: #18181B;
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
}

.v-c p {
	color: #70707b;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

.profile-image-container {
	position: relative;
	width: 150px;
}
.profile-edit-icon-container {
	position: absolute;
	right: 15px;
	bottom: 0px;
	cursor: pointer;
}
.profile-image {
	width: 150px;
	height: 150px;
	border-radius: 100%;
	object-fit: cover;
}
.profile-image-input {
	position: absolute;
	opacity: 0;
	z-index: -1;
}

.profile-sidebar-avi {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	object-fit: cover;
}

.profile-topbar-avi {
	width: 40px;
	height: 40px;
	border-radius: 100%;
	object-fit: cover;
}

button.print {
	display: none!important;
}

.comq {
	padding: 30px;
	border: 1px solid #E4E4E7;
	border-radius: 8px;
	box-shadow: 0px 4px 8px 0px #37C28D08;
}

.comq h2 {
	color: #18181B;
	font-size: 24px;
	line-height: 32px;
	font-weight: 500;
}

.comq h6 {
	color: #51525C;
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
}

.comq h5 {
	color: #70707B;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
}

.comq p {
	color: #18181B;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.table-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 30px 10px 15px;
}

.table-nav-input {
	width: 300px!important
}

.table-nav-link {
	font-size: 14px;
}

.table-nav-link:hover {
	background-color: #eee!important;
	cursor: pointer;
}
.bulk-actions {
	display: flex;
	justify-content: center;
	align-items: center;
}

.bulk-actions-title {
	margin: 0 20px 0 0;

}

.bulk-actions-selected {
	margin:  0 20px 0 0;
}

.bulk-actions-selected p {
	font-size: 14px;
	margin: 0;
}

.update-card {
	border: 1px solid #f4f4f4;
	padding: 10px 12px;
	box-shadow: 0px 0px 0px 0.5px #12376914;
	border-radius: 4px;
}

.update-card h4 {
	font-size: 16px;
	line-height: 24px;
	color: #000000;
	font-weight: 500;
	margin: 0;
}

.update-card p {
	font-size: 14px;
	line-height: 24px;
	color: #9CA3AF;
	font-weight: 400;
	margin: 0;
}

.mem-list {
	background: #DDF5EC;
	padding: 8px 10px;
	font-size: 12px;
	font-weight: 400;
	border-radius: 40px;
	margin-left: 6px;
}

.medal h6 {
	font-size: 14px;
	line-height: 20px;
	color: #3F3F46;
	font-weight: 600;
}

.them .form-check-input {
	width: 2em;
	height: 2em;
	margin-right: 10px;
	margin-top: -4px;
}

.them .form-check .form-check-input {
	float: left;
	margin-right: 10px;
}

.them .form-check-label {
	font-weight: 400;
	color: #70707B;
	font-size: 16px;
	line-height: 24px;
}

.them .form-check-input:checked {
	background-color: #7F56D9;
	border-color: #7F56D9;
}

.table-min-height {
	min-height: 500px;
}

.admin-listing-password {
	display: flex;
	width: 100%;
	gap: 100px;
	margin-top: 20px;
}

.admin-listing-password-form {
	width: 100%;
}

.admin-listing-password .button-group {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: end;
	width: 100%;
}

.admin-listing-password .login-btn {
	width: 200px;
	margin-top: 15px;
}

.admin-listing-password .login-transparent-btn {
    background-color: #fff !important;
    padding: 12px !important;
    border-color: #18181b !important;
    color: #18181b !important;
    font-weight: 500 !important;
	width: 200px;
}