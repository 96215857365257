.white-btn {
	background-color: #fff !important;
	padding: 12px !important;
	border-color: #fff !important;
	color: #18181b !important;
	font-weight: 600 !important;
}

.clients {
	padding: 50px 10px;
	background-color: #fff;
}

.clients h6 {
	font-size: 18px;
	font-weight: 500;
	color: #70707b;
	line-height: 28px;
}

.platform {
	background-color: #fafafa;
	padding: 100px 10px;
}

.platform h2 {
	font-size: 36px;
	font-weight: 700;
	color: #18181b;
	line-height: 44px;
}

.platform p {
	font-size: 20px;
	font-weight: 500;
	color: #51525c;
	line-height: 30px;
}

.home-top {
	padding-top: 100px;
}

.features {
	padding: 100px 10px;
	background-color: #fff;
}

.features h2 {
	color: #25815e;
	font-size: 18px;
	font-weight: 700;
	line-height: 24px;
}

.features h6 {
	color: #18181b;
	font-size: 36px;
	font-weight: 700;
	line-height: 44px;
}

.features p {
	color: #70707b;
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
}

.st {
	background-color: #ebf9f4;
}

.hometop {
	padding-top: 60px;
}

.st p {
	color: #70707b;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.st h3 {
	color: #18181b;
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
}

.featured {
	padding: 100px 10px;
	background-color: #034c3c;
}

.featured h6 {
	font-size: 18px;
	font-weight: 400;
	line-height: 21px;
}

.featured h2 {
	font-size: 30px;
	font-weight: 700;
	line-height: 38px;
}

.card-orgs {
	border: 2px solid #f4f4f5;
	border-radius: 12px;
}

.card-orgs h4 {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: #18181b;
}

.card-orgs p {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	color: #51525c;
}

.bg-feedback {
	border: 0.5px solid #ddf5ec;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.08);
	border-radius: 12px;
}

.feedback {
	padding: 100px 10px;
	background-color: #fafafa;
}

.feedback h2 {
	font-size: 30px;
	font-weight: 700;
	line-height: 38px;
	color: #1e1e1e;
}

.feedback h6 {
	font-size: 18px;
	font-weight: 400;
	line-height: 21px;
	color: #5f5f5f;
}

.bg-feedback h6 {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #70707b;
}

.bg-feedback p {
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	color: #26272b;
	margin: 0;
}

.bg-feedback h3 {
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	color: #70707b;
}

.getstarted {
	background-color: #fff;
	padding: 100px 20px;
}

.get-started {
	background: #034c3c;
	border-radius: 16px;
	padding: 70px 40px;
}

.get-started h3 {
	font-size: 30px;
	font-weight: 700;
	line-height: 38px;
}

.get-started p {
	color: #ffffff99;
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
}

.faq {
	background-color: #fafafa;
	padding: 100px 10px;
}

.faq h2 {
	color: #18181b;
	font-size: 36px;
	font-weight: 700;
	line-height: 33px;
}

.faq h6 {
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	color: #51525c;
	margin: 0;
}

.get-in {
	color: #027a48;
	text-decoration: underline !important;
}

.blog {
	padding: 100px 10px;
	background-color: #fff;
}

.blog h2 {
	font-size: 30px;
	font-weight: 700;
	line-height: 38px;
	color: #1e1e1e;
}

.blog h6 {
	font-size: 18px;
	font-weight: 400;
	line-height: 21px;
	color: #5f5f5f;
}

.tags {
	background: #ecfdf3;
	border-radius: 16px;
	padding: 8px 20px;
	font-size: 16px;
	color: #027a48;
	text-align: center;
}

.posts h2 {
	font-size: 18px;
	font-weight: 500;
	line-height: 28px;
	color: #18181b;
}

.posts a {
	color: #25815e;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
}

.footer-bottom {
	background: #034c3c;
	padding: 70px 20px 0px 20px;
}

.footer-bottom p {
	color: #fff;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.footer-bottom h4 {
	font-size: 14px;
	color: #d1d1d6;
	line-height: 20px;
	font-weight: 500;
	margin-bottom: 20px;
}

.list-unstyled {
	padding-left: 0;
}

.footer-bottom .links a {
	display: block;
	color: #e4e4e7 !important;
	font-size: 16px;
	font-weight: 50;
	line-height: 40px;
	text-decoration: none;
}

.links li {
	float: left;
	padding-right: 22px;
}

.bottom-line {
	padding-bottom: 60px;
	border-bottom: 1px solid #51525c;
}

.copyright {
	padding: 25px 0px;
}

.copyright h6 {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	color: #d1d1d6;
}

.copyright a {
	color: #a0a0ab;
}

.copyright a:hover {
	color: #fff;
}

.directorybanner {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url('./img/directory.svg');
	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 130px 20px;
}

.ani {
	animation-name: slider;
	animation-duration: 20s;
	animation-iteration-count: infinite;
	/* animation-delay: -2s */
}

@keyframes slider {
	0% {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
			url('./img/directory.svg');
	}
	25% {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
			url('./img/slider-1.svg');
	}
	50% {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
			url('./img/directory.svg');
	}
	100% {
		background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
			url('./img/slider-1.svg');
	}
}
/* 
@keyframes slider {
  0%   {background-image: linear-gradient(rgb(72,0,72,0.8), rgb(192,72,72,0.8)),url('./img/directory.svg');}
  25%  {background-image: url('./img/slider-1.svg');}
  50%  {background-image: url('./img/directory.svg');}
  100% {background-image: url('./img/slider-1.svg');}
} */

.directorybanner h1 {
	font-size: 36px;
	font-weight: 500;
	line-height: 44px;
}

.directorybanner h6 {
	font-size: 18px;
	font-weight: 400;
	line-height: 27px;
}

.form-control:focus,
.form-select:focus {
	box-shadow: none !important;
	/* border: none!important; */
}

.form-search {
	position: relative;
}

.form-search .bi-search {
	position: absolute;
	top: 16px;
	left: 20px;
	color: #9ca3af;
}

.form-search span {
	position: absolute;
	right: 17px;
	top: 13px;
	padding: 2px;
	border-left: 1px solid #d1d5db;
}

.form-input {
	height: 55px;
	text-indent: 33px;
	border-radius: 4px !important;
	border: 1px solid #d1d1d6 !important;
}

.directory-search {
	background: #fcfcfc;
	border-radius: 8px;
}

.directorycontent {
	background-color: #fcfcfc;
	padding: 90px 20px;
}

.filterby {
	border: 1px solid #e4e4e7;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 12px;
}

.direct-cards {
	border: 1px solid #e4e4e7;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 12px;
}

.text-direct h2 {
	color: #18181b;
	font-size: 18px;
	line-height: 28px;
	font-weight: 700;
}

.text-direct a {
	color: #18181b;
	font-size: 18px;
	line-height: 28px;
	font-weight: 700;
}

.text-direct h6 {
	color: #2ea275;
	font-size: 15px;
	line-height: 19px;
	font-weight: 500;
}

.text-direct p {
	color: #51525c;
	font-size: 16px;
	line-height: 19px;
	font-weight: 500;
}

@media (max-width: 767.98px) {
	.mobile-row {
		flex-direction: column !important;
	}
}

.dbottom {
	padding: 60px 40px;
	background: #ebf9f4;
	border: 1px solid #bbecd9;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 16px;
}

.directorybottom {
	background-color: #fcfcfc;
	padding: 0px 20px 90px 20px;
}

.directorybottom h2 {
	color: #26272b;
	font-size: 30px;
	line-height: 38px;
	font-weight: 700;
}

.directorybottom h6 {
	color: #51525c;
	font-size: 20px;
	line-height: 30px;
	font-weight: 400;
}

.tarea {
	border: 1px solid #e4e4e7;
	border-radius: 8px;
}

.thematic-areas {
	height: 307px;
	overflow-x: hidden;
	overflow-y: auto;
}

.filterby h4 {
	color: #18181b;
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
}

.filterby h5 {
	color: #26272b;
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
}

.filterby h6 {
	color: #25815e;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.filterby label {
	color: #3f3f46;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
}

.filterby .form-check-input:checked {
	background-color: #2ea275;
	border-color: #2ea275;
}

.pro {
	background-image: url('./img/pro.png') !important;
}

.proj {
	border: 1px solid #e4e4e7;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 12px;
}

.proj p {
	color: #51525c;
	font-size: 12px;
	line-height: 18px;
	font-weight: 500;
}

.proj a {
	color: #26272b;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
}

.proj h6 {
	color: #26272b;
	font-size: 16px;
	line-height: 24px;
	font-weight: 700;
}

.left-pro {
	/* background-image: url('./img/left-pro.svg'); */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 200px 20px;
}

@media (min-width: 992px) {
	.right-pro {
		padding: 200px 270px 250px 40px;
		background-color: #fcfcfc;
	}
}

@media (max-width: 767.98px) {
	.right-pro {
		padding: 100px 20px;
		background-color: #fcfcfc;
	}
}

.right-pro h2 {
	color: #18181b;
	font-size: 48px;
	line-height: 60px;
	font-weight: 700;
}

.right-pro p {
	color: #51525c;
	font-size: 14px;
	line-height: 21px;
	font-weight: 500;
}

.coat {
	background: #f9f5e7;
	border-radius: 16px;
	padding: 3px 12px;
	font-size: 12px;
}

.project-desc {
	background-color: #fff;
	padding: 60px 20px;
}

.project-desc h2 {
	color: #18181b;
	font-size: 30px;
	line-height: 45px;
	font-weight: 700;
}

.project-desc p {
	color: #51525c;
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
	margin: 0;
}

.singledirectory {
	background: #034c3c;
	padding: 100px 20px;
}

.services-rendered {
	padding: 60px 20px;
	background-color: #FCFCFC;
	border-bottom: 0.8px solid #E4E4E7;
}

.services-rendered h6 {
	font-size: 18px;
	line-height: 21px;
	color: #26272b;
	font-weight: 600;
	margin: 0;
}

.services-rendered p {
	font-size: 18px;
	line-height: 24px;
	color: #51525c;
	font-weight: 500;
}

.location {
	padding: 60px 20px;
	background-color: #fff;
}

.location h2 {
	color: #26272b;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
}

.projdirectory {
	background-color: #fcfcfc;
	padding: 100px 20px;
}

.projdirectory h2 {
	color: #26272b;
	font-size: 24px;
	line-height: 28px;
	font-weight: 700;
}

.progress-bar {
	background-color: #37c28d !important;
}

.act-pro span {
	color: #3f3f46;
	font-size: 18px;
	line-height: 28px;
	font-weight: 600;
	padding-top: 20px;
}

.bg-disclaimer {
	border: 1px solid #2ea275;
	border-radius: 4px;
	background: #1c6146;
	padding: 15px;
}

.bg-disclaimer p {
	font-size: 12px;
	line-height: 18px;
}

.bg-disclaimer h3 {
	font-size: 14px;
	line-height: 20px;
}

.resource-type::first-letter {
	text-transform: capitalize;
}

.compliance-req h3 {
	color: #26272B;
	font-size: 24px;
	line-height: 32px;
	font-weight: 600;
}

.compliance-req h4 {
	color: #26272B;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

.compliance-req h5 {
	color: #26272B;
	font-size: 12px;
	line-height: 20px;
	font-weight: 400;
}

@media (min-width: 992px){
	.compliance-req h2 {
		color: #18181B;
		font-size: 18px;
		line-height: 28px;
		font-weight: 600;
	}
}

@media (max-width: 767.98px){
	.compliance-req h2 {
		color: #18181B;
		font-size: 18px;
		line-height: 28px;
		font-weight: 600;
		width: 223px;
	}
}

.compliance-req p {
	color: #26272B;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
}

.dwh {
	color: #039855;
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;
	cursor: pointer;
}

.comp-card {
	border: 0.5px solid #D1D1D6;
	box-shadow: 0px 4px 8px rgba(55, 194, 141, 0.03);
	border-radius: 4px;
	padding: 30px;
	margin-bottom: 30px;
}

.comp-card h4 {
	color: #18181B;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}

.tan {
	background: #F1F7D4;
	padding: 6px 16px;
	font-size: 12px;
	border-radius: 50px;
}

.aboutsec {
	padding: 130px 20px;
}

.aboutsec h2 {
	color: #18181B;
	font-size: 48px;
	font-weight: 600;
	font-family: "Gilmer Bold"!important;
	line-height: 60px;
}

.aboutsec p {
	color: #70707B;
	font-size: 18px;
	font-weight: 400;
	line-height: 28px;
}